import { default as _91id_93uhd8WPSNNNMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/about/[id].vue?macro=true";
import { default as _91id_939F7OakloOiMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/articles/[id].vue?macro=true";
import { default as index4xXfPZnOnjMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/articles/index.vue?macro=true";
import { default as email_45reset_45passwordA94HiIoY7ZMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/email-reset-password.vue?macro=true";
import { default as forgotqMUSCvxwylMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/forgot.vue?macro=true";
import { default as loginYRpvGA7SbRMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/login.vue?macro=true";
import { default as phone_45reset_45passwordw0NvVDliMGMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/phone-reset-password.vue?macro=true";
import { default as register0tWsQbM5rMMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/register.vue?macro=true";
import { default as index3KCwlYyvfyMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index.vue?macro=true";
import { default as _providerAuwnbGnMe4Meta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/social/_provider.vue?macro=true";
import { default as verify_45emailjllUvTMpx1Meta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/verify-email.vue?macro=true";
import { default as cart5P8lfMlClVMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/cart.vue?macro=true";
import { default as categoriesFhBdGpQrmsMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/categories.vue?macro=true";
import { default as indexdxEnZB0ouVMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/index.vue?macro=true";
import { default as _91id_93PWJjo5aStMMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/materials/[id].vue?macro=true";
import { default as indexwaJqjmaP1UMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/materials/index.vue?macro=true";
import { default as editpu94Ma58uOMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/edit.vue?macro=true";
import { default as indexP7DprkrpU6Meta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/index.vue?macro=true";
import { default as invite_45friendsqpCjwDR1FdMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/invite-friends.vue?macro=true";
import { default as money_45movements3KWAvzy2SfMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/money-movements.vue?macro=true";
import { default as notificationsvuAe4rYYX7Meta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/notifications.vue?macro=true";
import { default as _91id_93EVpxqYSehsMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/orders/[id].vue?macro=true";
import { default as indexDYFsMDD9XpMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/orders/index.vue?macro=true";
import { default as places6zeWaKVYanMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/places.vue?macro=true";
import { default as wishlistXGay7i4MAWMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/wishlist.vue?macro=true";
import { default as indexAi85BPAh5cMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index.vue?macro=true";
import { default as offersKlYNG07cReMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/offers.vue?macro=true";
export default [
  {
    name: "about-id___en",
    path: "/en/about/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/about/[id].vue")
  },
  {
    name: "about-id___tr",
    path: "/tr/about/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/about/[id].vue")
  },
  {
    name: "about-id___ar",
    path: "/about/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/about/[id].vue")
  },
  {
    name: "articles-id___en",
    path: "/en/articles/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/articles/[id].vue")
  },
  {
    name: "articles-id___tr",
    path: "/tr/articles/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/articles/[id].vue")
  },
  {
    name: "articles-id___ar",
    path: "/articles/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/articles/[id].vue")
  },
  {
    name: "articles___en",
    path: "/en/articles",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/articles/index.vue")
  },
  {
    name: "articles___tr",
    path: "/tr/articles",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/articles/index.vue")
  },
  {
    name: "articles___ar",
    path: "/articles",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/articles/index.vue")
  },
  {
    name: "auth___en",
    path: "/en/auth",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index.vue"),
    children: [
  {
    name: "auth-index-email-reset-password___en",
    path: "email-reset-password",
    meta: email_45reset_45passwordA94HiIoY7ZMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/email-reset-password.vue")
  },
  {
    name: "auth-index-forgot___en",
    path: "forgot",
    meta: forgotqMUSCvxwylMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/forgot.vue")
  },
  {
    name: "auth-index-login___en",
    path: "login",
    meta: loginYRpvGA7SbRMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/login.vue")
  },
  {
    name: "auth-index-phone-reset-password___en",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordw0NvVDliMGMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/phone-reset-password.vue")
  },
  {
    name: "auth-index-register___en",
    path: "register",
    meta: register0tWsQbM5rMMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/register.vue")
  }
]
  },
  {
    name: "auth___tr",
    path: "/tr/auth",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index.vue"),
    children: [
  {
    name: "auth-index-email-reset-password___tr",
    path: "email-reset-password",
    meta: email_45reset_45passwordA94HiIoY7ZMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/email-reset-password.vue")
  },
  {
    name: "auth-index-forgot___tr",
    path: "forgot",
    meta: forgotqMUSCvxwylMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/forgot.vue")
  },
  {
    name: "auth-index-login___tr",
    path: "login",
    meta: loginYRpvGA7SbRMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/login.vue")
  },
  {
    name: "auth-index-phone-reset-password___tr",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordw0NvVDliMGMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/phone-reset-password.vue")
  },
  {
    name: "auth-index-register___tr",
    path: "register",
    meta: register0tWsQbM5rMMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/register.vue")
  }
]
  },
  {
    name: "auth___ar",
    path: "/auth",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index.vue"),
    children: [
  {
    name: "auth-index-email-reset-password___ar",
    path: "email-reset-password",
    meta: email_45reset_45passwordA94HiIoY7ZMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/email-reset-password.vue")
  },
  {
    name: "auth-index-forgot___ar",
    path: "forgot",
    meta: forgotqMUSCvxwylMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/forgot.vue")
  },
  {
    name: "auth-index-login___ar",
    path: "login",
    meta: loginYRpvGA7SbRMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/login.vue")
  },
  {
    name: "auth-index-phone-reset-password___ar",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordw0NvVDliMGMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/phone-reset-password.vue")
  },
  {
    name: "auth-index-register___ar",
    path: "register",
    meta: register0tWsQbM5rMMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/index/register.vue")
  }
]
  },
  {
    name: "auth-social-_provider___en",
    path: "/en/auth/social/_provider",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/social/_provider.vue")
  },
  {
    name: "auth-social-_provider___tr",
    path: "/tr/auth/social/_provider",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/social/_provider.vue")
  },
  {
    name: "auth-social-_provider___ar",
    path: "/auth/social/_provider",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/social/_provider.vue")
  },
  {
    name: "auth-verify-email___en",
    path: "/en/auth/verify-email",
    meta: verify_45emailjllUvTMpx1Meta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify-email___tr",
    path: "/tr/auth/verify-email",
    meta: verify_45emailjllUvTMpx1Meta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify-email___ar",
    path: "/auth/verify-email",
    meta: verify_45emailjllUvTMpx1Meta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/auth/verify-email.vue")
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/cart.vue")
  },
  {
    name: "cart___tr",
    path: "/tr/cart",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/cart.vue")
  },
  {
    name: "cart___ar",
    path: "/cart",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/cart.vue")
  },
  {
    name: "categories___en",
    path: "/en/categories",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/categories.vue")
  },
  {
    name: "categories___tr",
    path: "/tr/categories",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/categories.vue")
  },
  {
    name: "categories___ar",
    path: "/categories",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/categories.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/index.vue")
  },
  {
    name: "index___tr",
    path: "/tr",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/index.vue")
  },
  {
    name: "materials-id___en",
    path: "/en/materials/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/materials/[id].vue")
  },
  {
    name: "materials-id___tr",
    path: "/tr/materials/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/materials/[id].vue")
  },
  {
    name: "materials-id___ar",
    path: "/materials/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/materials/[id].vue")
  },
  {
    name: "materials___en",
    path: "/en/materials",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/materials/index.vue")
  },
  {
    name: "materials___tr",
    path: "/tr/materials",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/materials/index.vue")
  },
  {
    name: "materials___ar",
    path: "/materials",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/materials/index.vue")
  },
  {
    name: indexAi85BPAh5cMeta?.name,
    path: "/en/my-account",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index.vue"),
    children: [
  {
    name: "my-account-index-edit___en",
    path: "edit",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/edit.vue")
  },
  {
    name: "my-account-index___en",
    path: "",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/index.vue")
  },
  {
    name: "my-account-index-invite-friends___en",
    path: "invite-friends",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/invite-friends.vue")
  },
  {
    name: "my-account-index-money-movements___en",
    path: "money-movements",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/money-movements.vue")
  },
  {
    name: "my-account-index-notifications___en",
    path: "notifications",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/notifications.vue")
  },
  {
    name: "my-account-index-orders-id___en",
    path: "orders/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/orders/[id].vue")
  },
  {
    name: "my-account-index-orders___en",
    path: "orders",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/orders/index.vue")
  },
  {
    name: "my-account-index-places___en",
    path: "places",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/places.vue")
  },
  {
    name: "my-account-index-wishlist___en",
    path: "wishlist",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/wishlist.vue")
  }
]
  },
  {
    name: indexAi85BPAh5cMeta?.name,
    path: "/tr/my-account",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index.vue"),
    children: [
  {
    name: "my-account-index-edit___tr",
    path: "edit",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/edit.vue")
  },
  {
    name: "my-account-index___tr",
    path: "",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/index.vue")
  },
  {
    name: "my-account-index-invite-friends___tr",
    path: "invite-friends",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/invite-friends.vue")
  },
  {
    name: "my-account-index-money-movements___tr",
    path: "money-movements",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/money-movements.vue")
  },
  {
    name: "my-account-index-notifications___tr",
    path: "notifications",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/notifications.vue")
  },
  {
    name: "my-account-index-orders-id___tr",
    path: "orders/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/orders/[id].vue")
  },
  {
    name: "my-account-index-orders___tr",
    path: "orders",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/orders/index.vue")
  },
  {
    name: "my-account-index-places___tr",
    path: "places",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/places.vue")
  },
  {
    name: "my-account-index-wishlist___tr",
    path: "wishlist",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/wishlist.vue")
  }
]
  },
  {
    name: indexAi85BPAh5cMeta?.name,
    path: "/my-account",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index.vue"),
    children: [
  {
    name: "my-account-index-edit___ar",
    path: "edit",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/edit.vue")
  },
  {
    name: "my-account-index___ar",
    path: "",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/index.vue")
  },
  {
    name: "my-account-index-invite-friends___ar",
    path: "invite-friends",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/invite-friends.vue")
  },
  {
    name: "my-account-index-money-movements___ar",
    path: "money-movements",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/money-movements.vue")
  },
  {
    name: "my-account-index-notifications___ar",
    path: "notifications",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/notifications.vue")
  },
  {
    name: "my-account-index-orders-id___ar",
    path: "orders/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/orders/[id].vue")
  },
  {
    name: "my-account-index-orders___ar",
    path: "orders",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/orders/index.vue")
  },
  {
    name: "my-account-index-places___ar",
    path: "places",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/places.vue")
  },
  {
    name: "my-account-index-wishlist___ar",
    path: "wishlist",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/my-account/index/wishlist.vue")
  }
]
  },
  {
    name: "offers___en",
    path: "/en/offers",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/offers.vue")
  },
  {
    name: "offers___tr",
    path: "/tr/offers",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/offers.vue")
  },
  {
    name: "offers___ar",
    path: "/offers",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/offers.vue")
  }
]